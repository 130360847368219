import * as R from "ramda";
import { getBearerToken } from "./platformAuth";

export const redirect = (location) => {
  window.location = location;
};

export const redirectToMember = () => {
  redirect(process.env.REACT_APP_MEMBER_URL);
};

export const redirectToLogin = () => {
  redirect(
    `${process.env.REACT_APP_MEMBER_URL}/member/login?appName=${process.env.REACT_APP_APP_NAME}&path=promotional-properties`
  );
};

export const getHostOrigin = () => {
  return window.location.origin;
};

export const getURI = () => {
  return window.location.href;
};

export const getQueryParam = (name) =>
  new URLSearchParams(window.location.search).get(name);

export const randomString = (length) => {
  const bytes = new Uint8Array(length);
  const random = window.crypto.getRandomValues(bytes);
  const result = [];
  const charset =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~";
  random.forEach((c) => {
    result.push(charset[c % charset.length]);
  });
  return result.join("");
};

export const coerceNumericValues = (values) => {
  const coercedValues = { ...values };
  switch (values.propertyTypeDetails.type) {
    case "EMAIL":
      coercedValues.propertyTypeDetails.emailOpenRate =
        parseFloat(values.propertyTypeDetails.emailOpenRate) || 0;
      coercedValues.propertyTypeDetails.emailMailingListSize =
        parseInt(values.propertyTypeDetails.emailMailingListSize) || 0;
      break;
    default:
    // no coercion necessary
  }
  return coercedValues;
};

export const removeUnrelatedFields = (values) => {
  const valuesClone = { ...values };
  const {
    propertyTypeDetails: { type },
  } = valuesClone;
  if (type !== "BROWSER_EXTENSION") {
    delete valuesClone.propertyTypeDetails.browserExtensionDescription;
  }
  if (type !== "EMAIL") {
    delete valuesClone.propertyTypeDetails.emailMailingListSize;
    delete valuesClone.propertyTypeDetails.emailOpenRate;
  }
  return valuesClone;
};

export const withoutTypenames = (o) =>
  o &&
  (Array.isArray(o)
    ? R.map(withoutTypenames, o)
    : typeof o === "object"
    ? R.omit(["__typename"], R.map(withoutTypenames, o))
    : o);

export const authenticatedFetch = async (uri, options = { headers: {} }) => {
  const bearerToken = await getBearerToken();
  const optionsWithToken = R.mergeDeepRight(options, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  return fetch(uri, optionsWithToken);
};

export const getMessageKeyFromGraphQlError = (error) => {
  const errors = error["graphQLErrors"] || error["errors"];
  const errorKeys = Object.keys(
    R.pathOr({}, ["0", "extensions", "errors"], errors)
  );
  return `errors.${errorKeys[0] || "tryAgain"}`;
};

export const findOptionByValue = (options, value) =>
  R.find((option) => option.value === value, options);

export const isNullOrEmpty = R.either(R.isEmpty, R.isNil);

export const missing = (s) => !s || "" === s;

export const mapOptions = (labelKey, valueKey, values) =>
  values
    ? values.map((value) => ({
        label: value[labelKey],
        value: value[valueKey],
      }))
    : [];
