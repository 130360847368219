import { useEffect, useState } from "react";
import { getUser, verifyUser } from "../auth";
import { redirectToLogin } from "../utils/util";
import { getUserId } from "../utils/platformAuth";

export const useUserApi = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await verifyUser();
        const userId = await getUserId();
        const user = await getUser(userId);
        setUser(user);
      } catch (e) {
        console.log("Redirecting due to error", e);
        redirectToLogin();
      }
    };
    checkAuthentication();
  }, []);

  return user;
};
