import * as api from "./api";
import { redirectToMember } from "./utils/util";
import { getBearerToken } from "./utils/platformAuth";

export const rolesWithViewPermission = [
  "NON_SUPERUSER",
  "SUPERUSER",
  "OPERATOR",
  "ANALYZER",
  "BUSINESS",
  "TECHNICAL",
];
export const rolesWithManagePermission = [
  "NON_SUPERUSER",
  "SUPERUSER",
  "OPERATOR",
  "BUSINESS",
  "TECHNICAL",
];

// this is a relic from the days of yore when platform-redirect would navigate to
// an app's oauth-callback with a fake "oauth state" derived from the
// user state, auth cookies, etc. from the member area.
//
// The platform-redirect stopped invoking the oauth-callback when the new nav went live.
//
// Ergo, this callback should not be called for any reason. If somehow it is,
// that's a bug in the old platform-redirect and we will deal with it then.
export const oauthCallBack = async () => {
  redirectToMember();
};

export const getUser = async (userId) => {
  const accessToken = await getBearerToken();
  return await api.getUser(accessToken, userId);
};

export const verifyUser = async () => {
  const accessToken = await getBearerToken();
  return await api.verifyUser(accessToken);
};

export const userHasPermissions = (permissions, user, companyId = null) => {
  // In New Nav, the user's current company will be provided
  if (user.currentCompany) {
    const cc = user.currentCompany;
    const userIsEmployee = user.isEmployee || user.impersonator?.isEmployee;
    return (
      ["ACTIVE", "SETUP"].includes(cc.status) &&
      ["publisher"].includes(cc.type) &&
      (userIsEmployee || permissions.indexOf(cc.companyRole) > -1)
    );
  }

  // In Old Nav, the company ID is provided by a cookie
  const company = user.companies
    .filter((c) => c.status === "ACTIVE" || c.status === "SETUP")
    .filter((c) => c.type === "publisher")
    .find((c) => parseInt(c.id, 10) === parseInt(companyId, 10));

  return (
    user.isEmployee ||
    (!!company && permissions.indexOf(company.companyRole) > -1)
  );
};
